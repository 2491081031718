import React from 'react'
import DashboardContainer from './dashboard/DashboardContainer';

export default function UuidDashboard() {
    const splitHash = window.location.hash.substring(window.location.hash.indexOf('?') + 1);
    return (
        <div className='vh-100' >
            <DashboardContainer
                splitSize={0}
                dashboardUuid={splitHash as string}
                rootParent={undefined}
                parentProperties={{}}
                readonly={true}
            />
        </div>
    )
}
